export function getToken() {
    return localStorage.getItem('token')
}

export function setToken(token) {
    localStorage.setItem('token', token)
}

export function isStartedKentei(testId) {
    if(getKenteiData(testId)==null){
        return false
    }
    return true
}

export function getKenteiData(testId) {
    let key = "kentei-"+testId;
    if(localStorage.getItem(key)==null || localStorage.getItem(key) == "" || localStorage.getItem(key)=="null" ){
        return null
    }
    return JSON.parse(localStorage.getItem(key))
}

export function setKenteiData(testId,ans) {
    let key = "kentei-"+testId;
    localStorage.setItem(key, JSON.stringify(ans))
}
