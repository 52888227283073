import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import queryString from 'query-string'
import HeaderPart from './Header'
import FooterPart from './Footer'
import {getToken} from './AppActions'
import {Redirect} from 'react-router'
import WelcomeImage from '../../../assets/images/welcome.png'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class LoginPage extends React.Component {
    constructor() {
        super()
        this.state = {
            aolId: "",
            hash_key: "",
            disabled:false
        }
    }

    componentDidMount() {
        // TODO get key in url
        var key = queryString.stringify(queryString.parse(this.props.location.search)).substring(4)
        const elm = document.getElementById("alert");
        let alert = elm.innerHTML.trim();
        let close = (elm.dataset.close && elm.dataset.close=='true')?true:false;
        this.setState({
            hash_key: key,
            disabled:close
        })
        if(alert && alert.length > 0){
            if(close){
                toast.error(<div dangerouslySetInnerHTML={{ __html: alert }}/>, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    });
            }
            else{
                toast.warn(<div dangerouslySetInnerHTML={{ __html: alert }}/>, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    });
            }
        }
    }

    componentDidUpdate(prevProps,prevState) {
        let {authenticated} = this.props
        if (authenticated) {
            //this.context.router.history.push("/welcome")
            this.props.history.push('/welcome')
        }
    }

    setAOLId(evt) {
        this.setState({aolId:evt.target.value});
    }


    login(e) {
        e.preventDefault();
        if(!this.state.disabled){
            this.setState({
                disabled:true
            })
            this.props.dispatch(getToken(this.state.aolId, this.state.hash_key))
        }
    }

    render() {
        let {error, errCode} = this.props
        let {disabled} = this.state
        if (errCode > 0 && errCode != 3) {
            return <Redirect to="/error"/>
        }
        return (
            <div className="w-full">
                <HeaderPart/>
                <div className="p-10">
                    <div className="card">
                        <div className="card-header text-lg text-center" >新卒検定</div>
                        <div className="card-body">
                            <form className="md:mx-10 mx-2 mt-10">
                                <div className="flex justify-center">
                                    <input className="grow atama" type="text" name="aolID" id="aolID" placeholder="IDを入力してください" onChange={evt => this.setAOLId(evt)} ></input>
                                </div>
                                <div className="flex justify-center">
                                    <button disabled={disabled} onClick={(e) => this.login(e)} type="button" className="btn">ログイン</button>
                                </div>
                            </form>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
                <FooterPart/>
            </div>
        )
    }
}

LoginPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

LoginPage.contextTypes = {
    router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        aolId: state.app.aolId,
        authenticated: state.app.authenticated,
        error: state.app.error,
        errCode: state.app.errCode
    }
}

export default connect(mapStateToProps)(LoginPage)
