import { getKenteiData, setKenteiData, setToken } from '../utils';
import * as ActionTypes from './ActionTypes';

// Initial State
const initialState = {
    exam_elapse: 0,
    examLoaded: false,
    authenticated: false,
    loading: false,
    currentQuestionIndex: 0,
    aolId: 2,
    schoolList: [],
    schoolDetails: {},
    category: {},
    examination: {},
    answers: {},
    errCode: -1,
    error: ""
};

const AppReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.APP_GET_EXAM_REQUEST:
            return {
                ...state,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_EXAM_SUCCESS:
            let defaultAnswers = {}
            action.payload.examination.mst_exam_question_meta.map(function (meta) {
                meta.mst_exam_questions.map(function (question) {
                    defaultAnswers[question.id] = {}
                    question.mst_exam_answers.map(function (answer) {
                        if (question.mst_exam_question_candidates.length > 0) {
                            defaultAnswers[question.id][answer.id] = 0
                        }
                    })
                })
            })
            if(getKenteiData(action.payload.examination.id) != null ){
                defaultAnswers=getKenteiData(action.payload.examination.id)
            }
            return {
                ...state,
                loading: false,
                examLoaded: true,
                examination: action.payload.examination,
                category: action.payload.category,
                answers: defaultAnswers,
                exam_elapse: action.payload.exam_elapse,
                aolId: action.payload.aol_id,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_EXAM_FAILED:
            return {
                ...state,
                loading: false,
                errCode: action.payload.errCode,
                error: action.payload.error
            }
        case ActionTypes.APP_SUBMIT_EXAM_REQUEST:
            return {
                ...state,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_SUBMIT_EXAM_SUCCESS:
            return {
                ...state,
                loading: false,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_SUBMIT_EXAM_FAILED:
            return {
                ...state,
                loading: false,
                errCode: action.payload.errCode,
                error: action.payload.error
            }
        case ActionTypes.APP_REGISTER_EXAMINEE_REQUEST:
            return {
                ...state,
                published: false,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_REGISTER_EXAMINEE_SUCCESS:
            return {
                ...state,
                loading: false,
                published: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_REGISTER_EXAMINEE_FAILED:
            return {
                ...state,
                loading: false,
                published: false,
                errCode: 99,
                error: action.payload.error || action.payload.message,
                errStatusCode: action.statusCode,
            }
        case ActionTypes.APP_GET_SCHOOL_REQUEST:
            return {
                ...state,
                loading: true,
                schoolList: [],
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                schoolList: action.payload.data,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_SCHOOL_FAILED:
            return {
                ...state,
                loading: false,
                schoolList: [],
                errCode: 99,
                error: action.payload.error || action.payload.message,
                errStatusCode: action.statusCode,
            }
        case ActionTypes.APP_GET_SCHOOL_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                schoolDetails: {},
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_SCHOOL_DETAILS_SUCCESS:
            const schoolDetails = action.payload.data.reduce((prevObj, currentValue) => {
                prevObj[currentValue.faculty_name] = currentValue.department_list
                return prevObj
            }, {})
            return {
                ...state,
                loading: false,
                schoolDetails,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_SCHOOL_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                schoolDetails: {},
                errCode: 99,
                error: action.payload.error || action.payload.message,
                errStatusCode: action.statusCode,
            }
        case ActionTypes.APP_GET_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
                category: {},
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_CATEGORY_SUCCESS:
            return {
                ...state,
                loading: false,
                category: action.payload.data,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                category: {},
                errCode: 99,
                error: action.payload.error || action.payload.message,
                errStatusCode: action.statusCode,
            }
        case ActionTypes.APP_CHANGE_QUESTION:
            return {
                ...state,
                currentQuestionIndex: action.index,
            }

        case ActionTypes.APP_UPDATE_ANSWER:
            let answers = {
                ...state.answers
            }
            answers[action.question_id] = {
                ...state.answers[action.question_id]
            }
            answers[action.question_id][action.answer_id] = action.value
            // Add action.elapse
            answers[action.question_id]["elapse"] = action.elapse
            /*
            answers = {
                3 : {
                    10 : 15,
                    11 : 12,
                }
            }
            */
            setKenteiData(state.examination.id,answers)
            return {
                ...state,
                answers: answers
            }
        case ActionTypes.APP_GET_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_TOKEN_SUCCESS:
            setToken(action.payload.auth_token)
            return {
                ...state,
                loading: false,
                authenticated: true,
                errCode: 0,
                error: ""
            }
        case ActionTypes.APP_GET_TOKEN_FAILED:
            return {
                ...state,
                loading: false,
                authenticated: false,
                error: action.payload.error,
                errCode: action.payload.errCode,
            }
        case ActionTypes.APP_PUBLISH_KEY_REQUEST:
            return {
                ...state,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_PUBLISH_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                published: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_PUBLISH_KEY_FAILED:
            return {
                ...state,
                loading: false,
                published: false,
                error: action.payload.error,
                errCode: action.payload.errCode,
            }
        case ActionTypes.APP_GET_OVERVIEW_REQUEST:
            return {
                ...state,
                loading: true,
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_OVERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                category: {
                    ...action.payload.category,
                    exam_total_number: action.payload.exam_total_number
                },
                examination:{
                    id: action.payload.exam_id
                },
                errCode: -1,
                error: ""
            }
        case ActionTypes.APP_GET_OVERVIEW_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                errCode: action.payload.errCode
            }
        default:
            return state
    }
};


// Export Reducer
export default AppReducer;
