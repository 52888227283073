import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import QuestionPart from "./QuestionPart";

class ProblemPart extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let { problem } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <h4>問 {problem.question_no}</h4>
        </div>
        <div className="card-body container">
          <div className="row">
            <div className="col">
              <div
                style={{
                  overflow: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word"
                }}
                dangerouslySetInnerHTML={{ __html: problem.content }}
              >
              </div>
            </div>
          </div>
          {problem.mst_exam_questions.map(function(question, idx) {
            return (
              <div  className="row pb-8" key={idx}>
                <div  className="col" key={idx}>
                  <QuestionPart
                    key={idx}
                    problem={problem}
                    question={question}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ProblemPart.propTypes = {
  dispatch: PropTypes.func.isRequired
};

ProblemPart.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ProblemPart);
