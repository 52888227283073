import React from 'react'

const FormAgreement = ({ agreeValue, onClickAgree }) => {
  return (
    <div className="form__item">
      <div className="agree__textbox">
        新卒検定をご受検いただくと、当社専用のマイページとして株式会社 SHIFT 2026年卒新卒採用マイページを発行いたします。 
        <br />
        ※卒業年度が2026年度以降になる方につきましても、一律のご登録となりますのでご了承ください。
        <br />
        <br />
        ご登録いただいたメールアドレスに、マイページ発行に関してのご連絡を後日送付いたします。
        <br />
        また、マイページご登録完了後に、新卒検定の詳細結果についてもお知らせいたします。
        <br />
        <br />
        マイページの登録にあたり、最新の会員規約への同意が必要となります。
        <br />
        以下の会員規約をご確認いただき、同意いただきますようお願いいたします。
      </div>
      <div className="agree__rule">
        【株式会社SHIFTインターンシッププログラムおよび採用ホームページ会員サービス 会員規約】
        <br />
        <br />
        ○第１条（株式会社SHIFTインターンシッププログラムおよび採用ホームページ会員サービスの定義）
        <br />
        「株式会社SHIFTインターンシッププログラムおよび採用ホームページ会員サービス」（以下「会員サービス」とします）とは、株式会社SHIFT（以下「当社」とします）が提供するインターネット上で行う、インターンシップおよび採用活動に関する情報サービスのことをいいます。
        <br />
        <br />
        ○第２条（会員）
        <br />
        （１）会員とは、当社が定める方法によって会員サービスに登録を申し込み、当社がこれを承認した方をいいます。
        <br />
        （２）会員は、会員サービスにおける会員向けのサービスを受けることができます。
        <br />
        （３）会員は、入会の時点でこの規約（以下「本規約」とします）を承諾しなければなりません。会員が会員サービスを利用したときは、本規約を承認したものとみなします。
        <br />
        <br />
        ○第３条（会員ＩＤ番号とパスワード）
        <br />
        （１）会員は、会員ＩＤ番号を付与され、パスワードを登録するものとします。ただし、第５条に抵触すると当社が判断した場合は、会員ＩＤ番号を付与されないことがあります。
        <br />
        （２）会員は、会員ＩＤ番号およびパスワードを第三者に譲渡または貸与してはなりません。
        <br />
        （３）会員の会員ＩＤ番号およびパスワードの管理および使用は会員の責任とし、これらの使用上の過誤または第三者による不正使用等については、当社は一切の責任を負わないものとします。
        <br />
        <br />
        ○第４条（会員サービス）
        <br />
        （１）会員サービスの提供期間は、2024年4月1日～2026年3月31日（予定）とします。
        <br />
        （２）当社は、会員への事前の通知なくして、会員サービスを変更、中断、中止することがあり、会員はこれを承諾するものとします。
        <br />
        （３）会員は、システム障害などの事情により、会員サービス機能に支障が生じ、または会員サービスが停止する等の可能性があることを承諾するものとします。
        <br />
        <br />
        ○第５条（会員の禁止行為）
        <br />
        会員は以下の行為を行なわないものとします。
        <br />
        （１）他の会員、当社または第三者の著作権、肖像権、その他知的所有権を侵害する行為
        <br />
        （２）他の会員、当社または第三者の財産、信用、名誉、プライバシー、その他人権等を侵害する行為
        <br />
        （３）他の会員、当社または第三者を差別、批判、攻撃、誹謗中傷する行為
        <br />
        （４）会員サービスの運営を妨げる行為、またはその恐れのある行為
        <br />
        （５）当社の業務を阻害する行為、または不利益を与える行為
        <br />
        （６）個人的な勧誘行為、個人的な物品の売買行為、その他会員サービスを利用して、営業活動、営利を目的とした情報提供活動を行うこと
        <br />
        （７）会員サービスを通じて入手した情報を複製、販売、出版その他の方法により私的利用の範囲を超えて使用すること
        <br />
        （８）会員サービスにおける政治活動、選挙活動、宗教活動
        <br />
        （９）虚偽の内容に基づいて会員登録する行為
        <br />
        （10）犯罪的行為に結びつく行為、公序良俗に反する行為、その他、法律、法令に反する行為
        <br />
        （11）コンピュータウィルス等有害なプログラムを会員サービスに使用したり、他者に感染させる行為
        <br />
        （12）会員が自らまたは第三者をして、如何なる方法を問わず、会員サービスについて、複写、複製、転載、引用、配信（ネットワークに接続されたサーバへのアップロードを含む）、編集、翻案、改変、改竄、翻訳等をする行為
        <br />
        （13）会員が自らまたは第三者をして、会員サービスと同一または類似のものを作成する行為
        <br />
        （14）会員が自らまたは第三者をして、会員サービスに関する出版物等を発行する行為
        <br />
        （15）その他、当社が不適切であると判断する行為
        <br />
        <br />
        ○第６条（除名）
        <br />
        当社は、会員が本規約に違反したと判断した場合、または会員として不適切と判断した場合、会員に事前に通知することなく、会員サービスの提供を中止することができるものとし、会員はこれを承諾するものとします。
        <br />
        <br />
        ○第７条（損害賠償）
        <br />
        （１）当社は、会員サービスの利用、変更、中断、中止などにより発生した会員の損害すべてに対し、一切の責任を負わないものとします。また、会員サービスの利用により会員または第三者が被った損害に対しいかなる責任も負わないものとし、損害賠償をする義務は一切ないものとします。
        <br />
        （２）会員が本規約に違反したことにより当社または第三者に損害を与えた場合、会員は、直接、間接を問わず一切の損害を賠償する義務を負います。
        <br />
        <br />
        ○第８条（提供された情報の当社による利用および第三者への提供）
        <br />
        （１）会員は、会員から提供された情報（個人情報を含む）を、当社がインターンシップ実施と採用活動の目的およびこれに準じる情報提供の目的で利用することを承諾するものとします。なお、個人情報の取り扱いについては、本規約第１０条（個人情報の取扱い）に定める通りとします。
        <br />
        （２）当社は、会員から提供された個人情報をもとに、個人を特定できないように加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制約なく利用することができるものとします（当該利用には、当社による新サービスの開発検討等を含みますが、これらに限られません）。なお、この場合の著作権その他一切の権利は当社に帰属するものとします。
        <br />
        （３）当社は、正当な理由なく、利用目的を超えて会員の個人情報を使用したり公開したりしないものとします。なお、当社は、前項の統計データの作成について、第三者に委託する場合があり、会員はこれを承諾するものとします。
        <br />
        （４）会員から提供された個人情報は、第三者による就職情報サービスの向上のために利用・分析することを目的として当社から当該第三者に対して提供されるものとし、会員はこれを承諾するものとします。
        <br />
        <br />
        ○第９条（本規約の変更）
        <br />
        当社は本規約を随時変更することができるものとします。変更の内容についてはインターネット上に表示します。この表示後、会員が会員サービスを利用した時点で、当該会員はこの変更を承諾したものとみなします。変更を承諾できない会員は退会するものとします。
        <br />
        <br />
        ○第１０条（個人情報の取扱い）
        <br />
        （１）当社は、会員から提供された個人情報を、個人情報保護に関する法令、当社のプライバシーポリシー（https://www.shiftinc.jp/privacy/）および社内規程に則り、適切に利用・管理いたします。
        <br />
        （２）当社は、会員が提供した個人情報を、次の各号に定める目的のみに利用し、目的以外の目的で使用いたしません。
        <br />
        ①会員への情報提供、連絡のため
        <br />
        ②インターンシッププログラムおよび採用選考のため
        <br />
        （３）当社は、個人情報を、採用選考に関わる当社社員および当社から適性試験などの情報管理を委託している第三者に対して、適正に個人情報を取り扱う旨の契約を締結したうえで開示できるものとし、採用選考に関与しないいかなる者への開示、提供はいたしません。
        <br />
        （４）当社は、個人情報を当社のグループ会社と共同利用できるものとし、会員はこれに同意するものとします。なお、共同利用する個人情報の項目、共同利用の範囲、利用目的は以下の通りとします。
        <br />
        ①共同利用する個人情報の項目：氏名、住所、電話番号、メールアドレス、学校名、生年月日、顔写真、その他会員に関する情報。
        <br />
        ②共同利用の範囲：当社グループ会社各社（https://www.shiftinc.jp/company/outline/）。
        <br />
        ③利用目的：本条第２項に定める通りとします。
        <br />
        （５）当社は、個人情報を、当社において厳重に管理し、会員のインターンシップおよび採用活動（当年の採用目的や今後の採用活動への改善のほか、次年度以降の第二新卒採用活動を含む）にのみ利用し、会員による提供から2031年3月31日まで保管し、それ以降はただちに責任をもって削除いたします。
        <br />
        （６）会員は、個人情報の取り扱いについて当社に問い合わせが必要な場合には、「株式会社SHIFT 人事本部 人材戦略部 新卒採用グループ（050-1745-4288）宛て」に連絡を行うものとします。
        <br />
        <br />
        ○第１１条（準拠法ならびに管轄裁判所）
        <br />
        本規約の成立、効力、履行および解釈に関しては、日本法が適用されるものとします。本規約および会員サービスの利用に関する一切の訴訟については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        <br />
        <br />
        ○付則
        <br />
        本規約は2024年7月30日から実施します。
        <br />
        <br />
        株式会社SHIFT
        <br />
        <br />
      </div>
      <div className="agree__check">
        <input id="agreed" type="checkbox" name="agreed" checked={agreeValue} onChange={onClickAgree} />
        <label htmlFor="agreed" className="select-none">
          同意する
        </label>
      </div>
    </div>
  )
}

export default FormAgreement
