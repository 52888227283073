import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HeaderPart from "./Header";
import FooterPart from "./Footer";
import Modal from "./Modal";
import ProblemListPart from "./ProblemListPart";
import Timer from "./Timer";
import { getExam, submitExam } from "./AppActions";
import { getToken } from "../utils";
import { Redirect } from "react-router";

import { changeQuestion } from "./AppActions";

class ExamPage extends React.Component {
  constructor() {
    super();
    this.state = {
      alertShow: false,
      confirmShow: false,
      disabled:false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getExam());
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', (e) => {
      alert('戻るボタンは使えません。');
      history.go(1);
    });
  }
  prevQuestion() {
    let { examination, currentQuestionIndex } = this.props;
    if (currentQuestionIndex > 0) {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        });
      }, 150);
      return this.props.dispatch(changeQuestion(currentQuestionIndex - 1));
    }
  }

  nextQuestion() {
    let { examination, currentQuestionIndex } = this.props;
    if (currentQuestionIndex < examination.mst_exam_question_meta.length - 1) {
      setTimeout(() => {
        window.scrollTo({
          top: 0
        });
      }, 150);
      return this.props.dispatch(changeQuestion(currentQuestionIndex + 1));
    }
  }
  render() {
    if (getToken() == "" || getToken() == null) {
      return <Redirect to="/login" />;
    }
    let {
      category,
      loading,
      examLoaded,
      exam_elapse,
      authenticated,
      errCode,
      error,
      currentQuestionIndex, examination
    } = this.props;
    //console.log("err: ", errCode)
    if (errCode != -1) {
      return <Redirect to="/error" />;
    }

    let {alertShow,confirmShow,disabled} = this.state;

    const submit = () => {
      this.setState({disabled:true});
      this.props.dispatch(submitExam());
      this.setState({confirmShow:false});
      this.props.history.push("/finish")
    }

    const expire = () =>{
      this.setState({disabled:true});
      this.setState({alertShow:false});
      this.props.history.push("/finish")
    }


    const onComplete = () => {
      this.setState({disabled:true});
      this.props.dispatch(submitExam());
      this.setState({alertShow:true});
    };

    return (
      <div>
        <HeaderPart />
        <div className="fixed top-0 w-full z-20">
          <div className="mx-auto max-w-[800px] flex flex-row">
            <div className="ml-auto">
              <Timer
                callback={onComplete}
              ></Timer>
            </div>
            <button
              disabled={disabled}
              type="button"
              className="btn !my-1 border-none"
              onClick={() => {this.setState({confirmShow:true});}}
            >
              提出
            </button>
          </div>
        </div>
        <div className="container min-w-[370px]">
          {loading ? (
            <div className="row">
              <div className="text-center col">
                <h3>問題をロード中</h3>
              </div>
            </div>
          ) : examLoaded ? (
            <div>
              <div className="problem">
                <ProblemListPart />
              </div>
            </div>
          ) : null}

          <Modal show={alertShow} message={"回答期限を超過したため、提出しました。"} ok_callback={expire}></Modal>
          <Modal show={confirmShow} message={"回答を提出しますか？"} ok_callback={submit} ng_callback={()=>{this.setState({confirmShow:false});}}></Modal>
        </div>
        {examination.mst_exam_question_meta &&
        <div className="text-center fixed bottom-2 w-full">
          <div className="mx-auto max-w-[900px] flex flew-row">
            <div className="">
              {currentQuestionIndex > 0 ? (
                <button
                  className="btn"
                  onClick={() => this.prevQuestion()}
                >
                  戻る{" "}
                </button>
              ) : null}
            </div>
            <div className="ml-auto">
              {currentQuestionIndex <
              examination.mst_exam_question_meta.length - 1 ? (
                <button
                  className="btn"
                  onClick={() => this.nextQuestion()}
                >
                  次へ
                </button>
              ) : null}
            </div>
          </div>
        </div>
        }
        <FooterPart />
      </div>
    );
  }
}

ExamPage.propTypes = {
  dispatch: PropTypes.func.isRequired
};

ExamPage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentQuestionIndex: state.app.currentQuestionIndex,
    authenticated: state.app.authenticated,
    category: state.app.category,
    loading: state.app.loading,
    examLoaded: state.app.examLoaded,
    exam_elapse: state.app.exam_elapse,
    errCode: state.app.errCode,
    error: state.app.error,
    examination: {
      ...state.app.examination
    }
  };
}

export default connect(mapStateToProps)(ExamPage);
