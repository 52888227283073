import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import FooterPart from './Footer'
import HeaderPart from './Header'

class AlreadyRegisteredPage extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {}

  render() {
    const { error, errStatusCode } = this.props

    const REGISTER_EMAIL_EXISTED_ERROR = 'REGISTER_EMAIL_EXISTED_ERROR'
    const REGISTER_INFO_EXISTED_ERROR = 'REGISTER_INFO_EXISTED_ERROR'

    const MESSAGES = {
      [REGISTER_EMAIL_EXISTED_ERROR]: [
        '検定受検登録ありがとうございます。該当のメールアドレス宛に検定受検のご案内をお送りしましたので、ご確認よろしくお願いします。',
      ],
      [REGISTER_INFO_EXISTED_ERROR]: [
        'すでにSHIFTにエントリー・マイページご登録いただいているようです。重複登録を避けるため、マイページに登録されているメールアドレスに検定受検のご案内を送付させていただきました。登録されているメールアドレスまたはマイページよりご確認をよろしくお願いいたします。もし登録に身に覚えがない/メールが届かない場合は、運営にお問合せください。​​',
        '検定受検登録ありがとうございます。該当のメールアドレス宛に検定受検のご案内をお送りしましたのでご確認よろしくお願いします。​',
      ],
    }

    if (errStatusCode !== 409) {
      return <Redirect to="/login" />
    }

    if (!error || !MESSAGES.hasOwnProperty(error)) {
      return <Redirect to="/error" />
    }

    return (
      <div>
        <HeaderPart />
        <div className="card">
          <div className="card-header text-center">会員登録</div>
          <div className="card-body">
            <div className="py-10 px-4 whitespace-wrap">
              {MESSAGES[error].map((message, idx) => (
                <p key={`err-msg-${idx}`} className="mb-4">
                  {message}
                </p>
              ))}
            </div>
          </div>
        </div>
        <FooterPart />
      </div>
    )
  }
}

AlreadyRegisteredPage.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    authenticated: state.app.authenticated,
    aolId: state.app.aolId,
    errCode: state.app.errCode,
    error: state.app.error,
    errStatusCode: state.app.errStatusCode,
  }
}

export default connect(mapStateToProps)(AlreadyRegisteredPage)
