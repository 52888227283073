import axios from "axios";

export const API = Symbol("API");


export default store => next => action => {
    const params = action[API]
    // This is not for api middleware then push it next to other middleware
    if (typeof params == "undefined") {
        return next(action)
    }

    // console.log("=====API MIDDLEWARE=====")
    // console.log(params)

    let {url, method, payload, types, requestParams, successParams, errorParams} = params;

    payload = payload || {}
    requestParams = requestParams || {}
    successParams = successParams || {}
    errorParams = errorParams || {}

    const [ requestType, successType, errorType ] = types

    // dispatch the requestType as long as the request params
    next({
        type: requestType,
        ...requestParams,
    })

    let token = localStorage.getItem("token")
    let headers = {"Content-Type": "application/json"}
    if (token != "") {
        headers["X-Request-Token"] = token
    }

    let config = {
        headers: headers
    }
    if (method == "GET") {
        config.params = payload
    } else {
        config.data = payload
    }
    config.method = method

    return axios.request(url, config).then(response => {
        if (response.status == 200) {
            next({
                type: successType,
                successParams: successParams,
                payload: response.data
            })
        } else {
            next({
                type: errorType,
                errorParams: errorParams,
                payload: response.data
            })
        }
    }).catch(err => {
        if (err.response.status == 401 || err.response.status == 400) {
            next({
                type: errorType,
                successParams: successParams,
                payload: err.response.data
            })
        }
        else{
            // console.log(err)
            // console.log(err.response)
            next({
                type: errorType,
                statusCode: err.response.status,
                payload: err.response.data
            })
        }
    })

}
