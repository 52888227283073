import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'

import { ErrorMessage, FormAgreement, FormGroup, FormInputGroup, FormItem, FormRadioList, FormSelect } from '.'
import { isValidDate } from '../../dateUtils'
import {
  DAY_OPTIONS,
  INSTITUTION_CATEGORY_OPTIONS,
  MONTH_OPTIONS,
  PLANNED_GRAD_YEAR_OPTIONS,
  SCHOOL_CATEGORY_OPTIONS,
  SCHOOL_NOT_LISTED_OPTION,
  SCHOOL_OUTSIDE_JP_OPTION,
  YEAR_OPTIONS,
  mapToOptions,
} from './options'

const RegisterForm = ({ loading, onSubmit, schoolList, schoolDetails, onSearchSchool, onSearchSchoolDetails }) => {
  const [confirmedSearchCriteria, setConfirmedSearchCriteria] = useState(false)
  const [checkAgreement, setCheckAgreement] = useState(false)

  const validationSchema = Yup.object({
    // Basic Information
    email: Yup.string().email('メールアドレス は無効です').required('メールアドレス は必須です'),
    kanjiFirstName: Yup.string().required('漢字姓 は必須です'),
    kanjiLastName: Yup.string().required('漢字名 は必須です'),
    furiganaFirstName: Yup.string().required('カナ姓 は必須です'),
    furiganaLastName: Yup.string().required('カナ名 は必須です'),
    birthYear: Yup.string(),
    birthMonth: Yup.string(),
    birthDay: Yup.string(),
    birthday: Yup.string()
      .test('required-date', '生年月日 は必須です', (value) => {
        return !value || !value.split('-').some((v) => !v) // empty date
      })
      .test('valid-date', '生年月日 の日付が不正です', (value) => {
        return isValidDate(value)
      }),

    // School Information
    schoolCategory: Yup.string().required('学校区分 は必須です'),
    institutionCategory: Yup.string().required('設置区分 は必須です'),
    searchTerm: Yup.string(),
    tempUniversity: Yup.string(),
    university: Yup.string().required('大学 は必須です'),
    faculty: Yup.string().required('学部 は必須です'),
    department: Yup.string().required('学科 は必須です'),
    plannedGradYear: Yup.string().required('卒業（予定）年度 は必須です'),
  })

  const initialValues = {
    email: '',
    kanjiFirstName: '',
    kanjiLastName: '',
    furiganaFirstName: '',
    furiganaLastName: '',
    birthYear: '',
    birthMonth: '',
    birthDay: '',
    birthday: '',
    schoolCategory: null,
    institutionCategory: null,
    searchTerm: '',
    tempUniversity: '',
    university: '',
    faculty: '',
    department: '',
    plannedGradYear: '',
  }

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    setFieldValue('birthday', `${values.birthYear}-${values.birthMonth}-${values.birthDay}`)
  }, [values.birthYear, values.birthDay, values.birthMonth])

  const schoolOptions = useMemo(() => {
    return mapToOptions([...schoolList, SCHOOL_NOT_LISTED_OPTION])
  }, [schoolList])

  const facultyOptions = useMemo(() => {
    if (values.tempUniversity === SCHOOL_NOT_LISTED_OPTION) return

    return mapToOptions(Object.keys(schoolDetails))
  }, [schoolDetails])

  const departmentOptions = useMemo(() => {
    if (values.tempUniversity === SCHOOL_NOT_LISTED_OPTION) return

    let options = []
    if (values.institutionCategory !== SCHOOL_OUTSIDE_JP_OPTION && values.faculty) {
      options = mapToOptions(schoolDetails[values.faculty])
    }
    return options
  }, [values.faculty])

  const handleSelectChange = (selectedOption, action) => {
    setFieldValue(action.name, selectedOption.value)
  }
  const handleUniversitySelectChange = (selectedOption, _) => {
    setFieldValue('tempUniversity', selectedOption.value)

    if (selectedOption.value !== SCHOOL_NOT_LISTED_OPTION) {
      setFieldValue('university', selectedOption.value)
      onSearchSchoolDetails({ schoolName: selectedOption.value })
    } else {
      setFieldValue('university', '')
    }

    setFieldValue('department', '')
    setFieldValue('faculty', '')
  }

  useEffect(() => {
    if (values.tempUniversity === SCHOOL_NOT_LISTED_OPTION) return

    if (values.institutionCategory !== SCHOOL_OUTSIDE_JP_OPTION && values.faculty && departmentOptions.length > 0)
      setFieldValue('department', departmentOptions[0].value)
  }, [values.faculty])

  useEffect(() => {
    resetSchoolFields()
  }, [values.institutionCategory, values.schoolCategory])

  const handleSearch = () => {
    if (values.institutionCategory !== SCHOOL_OUTSIDE_JP_OPTION) {
      onSearchSchool({
        searchTerm: values.searchTerm,
        institutionCategory: values.institutionCategory,
        schoolCategory: values.schoolCategory,
      })
    }
    setConfirmedSearchCriteria(true)
  }

  const resetSchoolFields = () => {
    setFieldValue('tempUniversity', '')
    setFieldValue('university', '')
    setFieldValue('department', '')
    setFieldValue('faculty', '')
  }

  const handleClearSearch = () => {
    resetSchoolFields()
    setConfirmedSearchCriteria(false)
  }

  const renderError = (key) => {
    return errors[key] && touched[key] && <ErrorMessage key={key} message={errors[key]} />
  }

  const handleAgreement = () => {
    setCheckAgreement((prev) => !prev)
  }

  return (
    <form onSubmit={handleSubmit}>
      {/* Basic Information */}
      <FormGroup title="基本情報">
        <FormItem
          label="漢字氏名"
          labelStyle={{ marginTop: '20px' }}
          topBorder={false}
          required
          helperText={['kanjiLastName', 'kanjiFirstName'].map(renderError)}
        >
          <FormInputGroup
            itemKey="kanjiLastName"
            label="漢字姓"
            currentValue={values.kanjiLastName}
            onChange={handleChange}
          />
          <FormInputGroup
            itemKey="kanjiFirstName"
            label="漢字名"
            currentValue={values.kanjiFirstName}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem
          label="カナ氏名"
          labelStyle={{ marginTop: '20px' }}
          required
          helperText={['furiganaLastName', 'furiganaFirstName'].map(renderError)}
        >
          <FormInputGroup
            itemKey="furiganaLastName"
            label="カナ姓"
            currentValue={values.furiganaLastName}
            onChange={handleChange}
          />
          <FormInputGroup
            itemKey="furiganaFirstName"
            label="カナ名"
            currentValue={values.furiganaFirstName}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="メールアドレス" required helperText={['email'].map(renderError)}>
          <FormInputGroup
            itemKey="email"
            placeholder="メールアドレス"
            currentValue={values.email}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem label="生年月日" required helperText={['birthday'].map(renderError)}>
          <FormSelect
            itemKey="birthYear"
            className="w-28"
            currentValue={values.birthYear}
            endLabel="年"
            options={YEAR_OPTIONS}
            onChange={handleSelectChange}
          />
          <FormSelect
            itemKey="birthMonth"
            className="w-20"
            currentValue={values.birthMonth}
            endLabel="月"
            options={MONTH_OPTIONS}
            onChange={handleSelectChange}
          />
          <FormSelect
            itemKey="birthDay"
            className="w-20"
            currentValue={values.birthDay}
            endLabel="日"
            options={DAY_OPTIONS}
            onChange={handleSelectChange}
          />
        </FormItem>
      </FormGroup>

      {/* School Information */}
      <FormGroup title="学校情報">
        <FormItem label="学校区分" topBorder={false} required helperText={['schoolCategory'].map(renderError)}>
          {confirmedSearchCriteria ? (
            <p className="font-bold">{SCHOOL_CATEGORY_OPTIONS[values.schoolCategory].label}</p>
          ) : (
            <FormRadioList
              itemKey="schoolCategory"
              list={SCHOOL_CATEGORY_OPTIONS}
              currentValue={values.schoolCategory}
              onChange={handleChange}
            />
          )}
        </FormItem>
        <FormItem label="設置区分" required helperText={['institutionCategory'].map(renderError)}>
          {confirmedSearchCriteria ? (
            <p className="font-bold">{INSTITUTION_CATEGORY_OPTIONS[values.institutionCategory].label}</p>
          ) : (
            <FormRadioList
              itemKey="institutionCategory"
              list={INSTITUTION_CATEGORY_OPTIONS}
              currentValue={values.institutionCategory}
              onChange={handleChange}
            />
          )}
        </FormItem>
        {values.institutionCategory === SCHOOL_OUTSIDE_JP_OPTION ? (
          <>
            <FormItem key="university-3" label="学校" required helperText={['university'].map(renderError)}>
              <FormInputGroup
                itemKey="university"
                placeholder="学校"
                currentValue={values.university}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem key="faculty-3" label="学部" required helperText={['faculty'].map(renderError)}>
              <FormInputGroup
                itemKey="faculty"
                placeholder="学部"
                currentValue={values.faculty}
                onChange={handleChange}
              />
            </FormItem>
            <FormItem key="department-3" label="学科" required helperText={['department'].map(renderError)}>
              <FormInputGroup
                itemKey="department"
                placeholder="学科"
                currentValue={values.department}
                onChange={handleChange}
              />
            </FormItem>
          </>
        ) : (
          <>
            <FormItem label="学校名の頭文字" required helperText={['searchTerm'].map(renderError)}>
              {confirmedSearchCriteria ? (
                <p className="font-bold">{values.searchTerm}</p>
              ) : (
                <FormInputGroup
                  itemKey="searchTerm"
                  placeholder=""
                  currentValue={values.searchTerm}
                  onChange={handleChange}
                />
              )}
              {confirmedSearchCriteria ? (
                <button
                  disabled={loading}
                  type="button"
                  onClick={handleClearSearch}
                  className="btn !w-28 !m-0 p-2 !bg-gray-600 hover:!bg-gray-500"
                >
                  条件変更
                </button>
              ) : (
                <button
                  disabled={loading || !values.searchTerm || !values.institutionCategory || !values.schoolCategory}
                  type="button"
                  onClick={handleSearch}
                  className="btn !w-28 !m-0 p-2"
                >
                  学校検索
                </button>
              )}
            </FormItem>
            <FormItem
              label="学校"
              required
              helperText={['university'].map(renderError)}
              contentClassName={`${values.tempUniversity === SCHOOL_NOT_LISTED_OPTION ? 'flex-col' : ''}`}
            >
              {confirmedSearchCriteria ? (
                schoolOptions.length > 0 && (
                  <>
                    <FormSelect
                      itemKey="tempUniversity"
                      wrapperClassName="w-full"
                      className="w-full"
                      currentValue={values.tempUniversity}
                      options={schoolOptions}
                      placeholder="（選択してください）"
                      onChange={handleUniversitySelectChange}
                    />

                    {values.tempUniversity === SCHOOL_NOT_LISTED_OPTION && (
                      <div className="flex flex-col items-start w-full">
                        <p className="text-sm text-gray-400">
                          リストに学校が表示されない場合は（リストにない学校）を選択してください。
                        </p>
                        <FormInputGroup
                          itemKey="university"
                          placeholder="学校"
                          currentValue={values.university}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </>
                )
              ) : (
                <p>（上記より学校を検索してください。）</p>
              )}
            </FormItem>
            {confirmedSearchCriteria && values.tempUniversity ? (
              Object.keys(facultyOptions).length > 0 && values.tempUniversity !== SCHOOL_NOT_LISTED_OPTION ? (
                <>
                  <FormItem label="学部" required helperText={['faculty'].map(renderError)}>
                    <FormSelect
                      itemKey="faculty"
                      wrapperClassName="w-full"
                      className="w-full"
                      currentValue={values.faculty}
                      options={facultyOptions}
                      placeholder="（選択してください）"
                      onChange={handleSelectChange}
                    />
                  </FormItem>
                  <FormItem label="学科" required helperText={['department'].map(renderError)}>
                    <FormSelect
                      itemKey="department"
                      wrapperClassName="w-full"
                      className="w-full"
                      currentValue={values.department}
                      options={departmentOptions}
                      placeholder="学部を選択すると学科が表示されます"
                      onChange={handleSelectChange}
                      disabled={!values.faculty}
                    />
                  </FormItem>
                </>
              ) : (
                <>
                  <FormItem label="学部" required helperText={['faculty'].map(renderError)}>
                    <FormInputGroup
                      itemKey="faculty"
                      currentValue={values.faculty}
                      placeholder="学部"
                      onChange={handleChange}
                    />
                  </FormItem>
                  <FormItem label="学科" required helperText={['department'].map(renderError)}>
                    <FormInputGroup
                      itemKey="department"
                      currentValue={values.department}
                      placeholder="学科"
                      onChange={handleChange}
                    />
                  </FormItem>
                </>
              )
            ) : (
              <></>
            )}
          </>
        )}
        <FormItem label="卒業（予定）年度" required helperText={['plannedGradYear'].map(renderError)}>
          <FormSelect
            itemKey="plannedGradYear"
            className="w-28"
            currentValue={values.plannedGradYear}
            endLabel="年"
            options={PLANNED_GRAD_YEAR_OPTIONS}
            onChange={handleSelectChange}
          />
        </FormItem>
      </FormGroup>

      {/* Agreement */}
      <FormAgreement agreeValue={checkAgreement} onClickAgree={handleAgreement} />

      {/* Submit */}
      <div className="form__bottom__textbox">内容を確認し、よろしければ送信にお進みください。</div>
      <div className="flex justify-center">
        <button disabled={loading || !checkAgreement} type="submit" className="btn !w-28">
          登録
        </button>
      </div>
    </form>
  )
}

export default RegisterForm
