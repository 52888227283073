import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import 'react-toastify/dist/ReactToastify.css'

import { CATEGORY_ERROR_PATH } from '../constants'
import { getCategory, getSchoolDetails, getSchoolList, registerExaminee } from './AppActions'
import Loading from './Loading'
import { RegisterForm } from './form'

class RegisterPage extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    const queryParams = queryString.parse(location.search)
    const examKey = queryParams['key']

    this.props.dispatch(getCategory({ examKey }))
    this.setState({ examKey })
  }

  componentDidUpdate(prevProps, prevState) {
    let { authenticated, published } = this.props
    if (authenticated) {
      this.props.history.push('/welcome')
    } else if (published) {
      this.props.history.push(REACT_APP_CONFIRM_URL)
    }
  }

  render() {
    const { loading, error, errStatusCode, schoolList, schoolDetails, category } = this.props

    if (errStatusCode === 409) {
      return <Redirect to="/already-registered" />
    }

    if (errStatusCode > 400 || (typeof error === 'string' && error.startsWith(CATEGORY_ERROR_PATH))) {
      return <Redirect to="/error" />
    }

    const handleSubmit = (values) => {
      this.props.dispatch(registerExaminee({ ...values, examKey: this.state.examKey }))
    }

    const handleSearchSchool = (searchParams) => {
      this.props.dispatch(getSchoolList(searchParams))
    }

    const handleSearchSchoolDetails = (searchParams) => {
      this.props.dispatch(getSchoolDetails(searchParams))
    }

    return (
      <main id="register-page" className="w-full">
        {loading && <Loading />}
        <div id="header__main">
          <div className="header__main__contents"></div>
          {/* <!-- HEADER LOGO --> */}
          <a href="/" className="header_logo" />
        </div>
        <div id="menu__contents__wrap">
          <div id="contents__wrap">
            <div id="contents__area">
              <div id="contents__area__wrap">
                <div id="contents__title__wrap">
                  <div id="contents__title">
                    <p>【{category.category_name}】登録フォーム</p>
                  </div>
                </div>
                <div id="main__contents">
                  <p>ページの案内に従い、入力・選択してください。</p>
                  {error && Object.keys(error).length > 0 && (
                    <div className="text-red-600 text-sm text-center mt-2 whitespace-pre">
                      {Object.values(error).join('\n')}
                    </div>
                  )}
                  <RegisterForm
                    loading={loading}
                    onSubmit={handleSubmit}
                    schoolList={schoolList}
                    schoolDetails={schoolDetails}
                    onSearchSchool={handleSearchSchool}
                    onSearchSchoolDetails={handleSearchSchoolDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="fixed bottom-0 w-full left-0 bg-black items-center justify-between">
          <span className="block text-sm text-white text-center ">Copyright SHIFT Inc. All Rights Reserved.</span>
        </footer>
      </main>
    )
  }
}

RegisterPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

RegisterPage.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    authenticated: state.app.authenticated,
    published: state.app.published,
    loading: state.app.loading,
    error: state.app.error,
    errCode: state.app.errCode,
    errStatusCode: state.app.errStatusCode,
    schoolList: state.app.schoolList,
    schoolDetails: state.app.schoolDetails,
    category: state.app.category,
  }
}

export default connect(mapStateToProps)(RegisterPage)
