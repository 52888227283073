import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import InputNumber from 'react-input-just-numbers'
import Select from 'react-select';
import {updateAnswer} from './AppActions'


class AnswerPart extends React.Component {
    constructor() {
        super()
        this.state = {
            defaultValue: 0,
        }
    }

    componentDidMount(){

    }

    handleUpdateAnswer(evt,self) {
        var value = evt.value;
        if( 'target' in evt){
            value = evt.target.value;
        }
        return self.props.dispatch(updateAnswer(self.props.question.id, self.props.answer.id, value))
    }

    formatOptionLabel(option) {
        return (
            <div>
                <div dangerouslySetInnerHTML={{ __html: option.label }}></div>
            </div>
        )
    }
    render() {
        let {answer, choices, question, answers} = this.props;
        var content = answer.content.split(/%DROP%|%NUMBER%|%RADIO%/);
        var content_pre = content[0];
        var content_post = (content.length >= 1)?content[1]:null;
        var is_dropdown = answer.content.indexOf("%DROP%") >= 0;
        var is_radio = answer.content.indexOf("%RADIO%") >= 0;
        var is_number = answer.content.indexOf("%NUMBER%") >= 0;
        let value = answers[question.id] != null ? (answers[question.id][answer.id] != null ? answers[question.id][answer.id] : null ): ((is_radio || choices.length == 0 )?null:0);
        let handler = this.handleUpdateAnswer;
        let self = this;
        const options = [];
        if(is_dropdown){
            choices.map(function (choice, idx) {
                options.push({ value: choice.id, label: choice.candidate_name });
            });
        }
        let selectClass = ""
        if(!content_post){
            selectClass += "col w-full"
        }
        return (
            <div className="row">
                {
                    content_pre && <div className="col leading-9 whitespace-nowrap">{content_pre}</div>
                }
                {
                    is_dropdown &&
                    <div className={selectClass}>

                        <Select
                            classNamePrefix="the-atama-select" className="min-w-[100px]" name="choices" value={value?options.find((v)=>v.value==value):null} instanceId="answer" placeholder={'-'} options={options} onChange={(evt) => this.handleUpdateAnswer(evt,self)}
                            formatOptionLabel={(option) => this.formatOptionLabel(option)}
                            noOptionsMessage={() => "選択肢は存在しません。"}
                        />
                    </div>
                }
                {
                    is_radio &&
                    (
                        <div className="col">
                        {
                            choices.map(function (choice, idx) {
                                return (
                                    <div className="row flex items-center w-full hover:bg-slate-100" key={"choice"+answer.id+"-"+choice.id}>
                                        <input className="cursor-pointer atama" type="radio" name={"choice"+answer.id} id={"choice"+answer.id+"-"+choice.id} value={choice.id} checked={choice.id==value?true:false} onChange={(evt) => handler(evt,self)}>

                                        </input>
                                        {' '}
                                        <label className="w-full cursor-pointer" htmlFor={"choice"+answer.id+"-"+choice.id} dangerouslySetInnerHTML={{ __html: choice.candidate_name }}/>
                                    </div>
                                )
                            })
                        }
                        </div>
                    )
                }
                {
                    is_number && <InputNumber className="col atama" id="answer" onlydigits value={value==null?"":value} maxLength="15" placeholder='数値入力' onChange={(evt) => this.handleUpdateAnswer(evt,self)}/>
                }
                &nbsp;{(content_post) && <div className="col leading-9 w-full">{content_post}</div>}
            </div>
        )
    }
}

AnswerPart.propTypes = {
    dispatch: PropTypes.func.isRequired
}

AnswerPart.contextTypes = {
    router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        answers: state.app.answers
    }
}

export default connect(mapStateToProps)(AnswerPart)
