import * as ActionTypes from "./ActionTypes";
import { API } from "../api";

/**
    The action to trigger the change of question
    @params
        index ==> the position of the question in examination
*/
export function changeQuestion(index) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.APP_CHANGE_QUESTION,
      index: index
    });
  };
}

/**
    The action to update the answer for specific questions
    @oarams
        the answer object within the answer
*/
export function updateAnswer(question_id, answer_id, value) {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.APP_UPDATE_ANSWER,
      question_id: question_id,
      answer_id: answer_id,
      value: value,
      elapse: new Date().getTime() // add timestamp here
    });
  };
}

/**
    The action to get the examination from the api
*/
export function getExam() {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_EXAM_REQUEST,
          ActionTypes.APP_GET_EXAM_SUCCESS,
          ActionTypes.APP_GET_EXAM_FAILED
        ],
        url: "/api/start",
        payload: {},
        method: "GET"
      }
    });
  };
}
export function confirmExam(email, exam_key) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_PUBLISH_KEY_REQUEST,
          ActionTypes.APP_PUBLISH_KEY_SUCCESS,
          ActionTypes.APP_PUBLISH_KEY_FAILED
        ],
        url: "/api/token_request",
        payload: {
          email: email,
          exam_key: exam_key
        },
        method: "PUT"
      }
    });
  };
}
/**
    Get Token
*/
export function getToken(aol_id, hash_key) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_TOKEN_REQUEST,
          ActionTypes.APP_GET_TOKEN_SUCCESS,
          ActionTypes.APP_GET_TOKEN_FAILED
        ],
        url: "/api/token",
        payload: {
          aol_id: aol_id,
          hash_key: hash_key
        },
        method: "GET"
      }
    });
  };
}
/**
    Get Overview of examination for each examinee
*/
export function getExamOverview() {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_OVERVIEW_REQUEST,
          ActionTypes.APP_GET_OVERVIEW_SUCCESS,
          ActionTypes.APP_GET_OVERVIEW_FAILED
        ],
        url: "/api/overview",
        payload: {},
        method: "GET"
      }
    });
  };
}

/**
    Submit the result of examination
*/
export function submitExam() {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_SUBMIT_EXAM_REQUEST,
          ActionTypes.APP_SUBMIT_EXAM_SUCCESS,
          ActionTypes.APP_SUBMIT_EXAM_FAILED
        ],
        url: "/api/submit",
        payload: getState().app.answers,
        method: "POST"
      }
    });
  };
}

/**
    Register Examinee
*/
export function registerExaminee(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_REGISTER_EXAMINEE_REQUEST,
          ActionTypes.APP_REGISTER_EXAMINEE_SUCCESS,
          ActionTypes.APP_REGISTER_EXAMINEE_FAILED
        ],
        url: "/api/examinee",
        payload: {
          email: payload.email,
          kanji_first_name: payload.kanjiFirstName,
          kanji_last_name: payload.kanjiLastName,
          furigana_first_name: payload.furiganaFirstName,
          furigana_last_name: payload.furiganaLastName,
          university: payload.university,
          faculty: payload.faculty,
          department: payload.department,
          birthday: payload.birthday,
          school_category: parseInt(payload.schoolCategory) + 1,
          institution_category: parseInt(payload.institutionCategory) + 1,
          planned_grad_year: parseInt(payload.plannedGradYear),
          category_exam_key: payload.examKey,
        },
        method: "POST"
      }
    });
  };
}

/**
    Get School List
*/
export function getSchoolList(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_SCHOOL_REQUEST,
          ActionTypes.APP_GET_SCHOOL_SUCCESS,
          ActionTypes.APP_GET_SCHOOL_FAILED
        ],
        url: "/api/schools",
        payload: {
          q: payload.searchTerm,
          school_category: parseInt(payload.schoolCategory) + 1,
          institution_category: parseInt(payload.institutionCategory) + 1,
        },
        method: "GET"
      }
    });
  };
}

/**
    Get School Subgroup List
*/
export function getSchoolDetails(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_SCHOOL_DETAILS_REQUEST,
          ActionTypes.APP_GET_SCHOOL_DETAILS_SUCCESS,
          ActionTypes.APP_GET_SCHOOL_DETAILS_FAILED
        ],
        url: "/api/school/faculties",
        payload: {
          school_name: payload.schoolName,
        },
        method: "GET"
      }
    });
  };
}

/**
    Get Category by exam_key
*/
export function getCategory(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: API,
      [API]: {
        types: [
          ActionTypes.APP_GET_CATEGORY_REQUEST,
          ActionTypes.APP_GET_CATEGORY_SUCCESS,
          ActionTypes.APP_GET_CATEGORY_FAILED
        ],
        url: "/api/category",
        payload: {
          exam_key: payload.examKey,
        },
        method: "GET"
      }
    });
  };
}
