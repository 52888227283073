import React from 'react'
import Select from 'react-select'

const FormSelect = ({
  itemKey,
  currentValue,
  options,
  endLabel,
  onChange,
  className,
  wrapperClassName,
  disabled,
  defaultValue,
  placeholder = '-',
}) => {
  return (
    <div key={itemKey} className={`flex gap-1 items-center ${wrapperClassName}`}>
      <Select
        id={itemKey}
        classNamePrefix="the-atama-select"
        className={className}
        name={itemKey}
        value={currentValue ? options.find((v) => v.value === currentValue) : null}
        placeholder={placeholder}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        defaultValue={defaultValue}
      />
      {endLabel && <div className={`label-${itemKey}`}>{endLabel}</div>}
    </div>
  )
}

export default FormSelect
