import React from 'react'

const FormItemLabel = ({ label, required, className, labelStyle={}}) => {
  return (
    <div className={`text-sm w-full md:w-56 text-right ${className}`} style={{...labelStyle}}>
      <span className="font-semibold">{label}</span>
      {required && <span className="required-tag">必須</span>}
    </div>
  )
}

export default FormItemLabel
