import { createStore, applyMiddleware,compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import api from './api'
import createRootReducer from './reducers'

export function configureStore(history) {
  const routingMiddleware = routerMiddleware(history)
  // Middleware and store enhancers
  const enhancers = [
    applyMiddleware(thunk, api, routingMiddleware),
  ];

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    {},
    compose(...enhancers)
  )
  return store;
}
