import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import {configureStore } from "./store";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();
const store = configureStore(history);

export default function initReact(){
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>,
        document.querySelector("#examinations")
    )
}
