import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class Timer extends React.Component {
    constructor() {
        super()
        this.state = {
            timer: "--:--",
            timer_id: null,
            callback: null
        }

    }

    componentDidMount() {
    }
    getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60) + Math.floor((total / 1000 / 60 / 60) % 24) * 3600 ;
        return {
            total, minutes, seconds
        };
    }
    countDownTimer = (e) => {
        let { total, minutes, seconds } = this.getTimeRemaining(e);
        if (total >= 0) {
            this.setState(
                { timer: (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)}
            )
        }
        else{
            if(this.state.timer_id){
                clearInterval(this.state.timer_id)
                this.setState(
                    { timer_id: null }
                )
            }
            if(this.state.callback){
                this.state.callback();
            }
        }
    }
    componentWillUnmount() {
        if(this.state.timer_id){
            clearInterval(this.state.timer_id)
        }
    }
    componentDidUpdate(prevProps,prevState)  {
        const { exam_elapse ,callback} = this.props
        if(this.state.timer_id == null){
            if( exam_elapse > 0 ){
                let deadline = new Date();
                deadline.setSeconds(deadline.getSeconds() + exam_elapse);
                let self = this;
                const id = setInterval(() => {
                    self.countDownTimer(deadline);
                }, 1000)
                this.setState(
                    { timer_id: id }
                )
            }
        }
        if(callback && this.state.callback == null){
            this.setState(
                { callback: callback }
            )
        }
    }

    render() {
        const { exam_elapse } = this.props;
        if(exam_elapse < 0){
            if(this.state.callback){
                this.state.callback();
            }
        }
        return (
            <span
                className="block text-xl text-white mr-2 py-2"
            >
                <u>
                <tt>
                {this.state.timer}
                </tt>
                </u>
            </span>
        )
    }
}

Timer.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

Timer.contextTypes = {
    router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        exam_elapse: state.app.exam_elapse,
    }
}

export default connect(mapStateToProps)(Timer)
