import React from 'react'

const FormInputGroup = ({ label, itemKey, currentValue, onChange, placeholder, autocomplete="off" }) => {
  return (
    <div key={itemKey} className="flex flex-col w-full">
      {label && (
        <label htmlFor={itemKey} className="text-sm">
          {label}
        </label>
      )}
      <input
        className="atama"
        type="text"
        name={itemKey}
        id={itemKey}
        autoComplete={autocomplete}
        placeholder={placeholder || label}
        value={currentValue}
        onChange={onChange}
      />
    </div>
  )
}

export default FormInputGroup
