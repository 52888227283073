import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProblemPart from "./ProblemPart";

class ProblemListPart extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  render() {
    let { currentQuestionIndex, examination } = this.props;
    return (
      <div>
        <div className="h-full overflow-y-auto">
          <ProblemPart
            problem={examination.mst_exam_question_meta[currentQuestionIndex]}
          />
        </div>
      </div>
    );
  }
}

ProblemListPart.propTypes = {
  dispatch: PropTypes.func.isRequired
};

ProblemListPart.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentQuestionIndex: state.app.currentQuestionIndex,
    examination: {
      ...state.app.examination
    }
  };
}

export default connect(mapStateToProps)(ProblemListPart);
