export const APP_CHANGE_QUESTION = "APP_CHANGE_QUESTION"
export const APP_GET_EXAM_REQUEST = "APP_GET_EXAM_REQUEST"
export const APP_GET_EXAM_SUCCESS = "APP_GET_EXAM_SUCCESS"
export const APP_GET_EXAM_FAILED = "APP_GET_EXAM_FAILED"
export const APP_SUBMIT_EXAM_REQUEST = "APP_SUBMIT_EXAM_REQUEST"
export const APP_SUBMIT_EXAM_SUCCESS = "APP_SUBMIT_EXAM_SUCCESS"
export const APP_SUBMIT_EXAM_FAILED = "APP_SUBMIT_EXAM_FAILED"
export const APP_GET_TOKEN_REQUEST = "APP_GET_TOKEN_REQUEST"
export const APP_GET_TOKEN_SUCCESS = "APP_GET_TOKEN_SUCCESS"
export const APP_GET_TOKEN_FAILED = "APP_GET_TOKEN_FAILED"
export const APP_PUBLISH_KEY_REQUEST = "APP_PUBLISH_KEY_REQUEST"
export const APP_PUBLISH_KEY_SUCCESS = "APP_PUBLISH_KEY_SUCCESS"
export const APP_PUBLISH_KEY_FAILED = "APP_PUBLISH_KEY_FAILED"
export const APP_GET_OVERVIEW_REQUEST = "APP_GET_OVERVIEW_REQUEST"
export const APP_GET_OVERVIEW_SUCCESS = "APP_GET_OVERVIEW_SUCCESS"
export const APP_GET_OVERVIEW_FAILED = "APP_GET_OVERVIEW_FAILED"
export const APP_UPDATE_ANSWER = "APP_UPDATE_ANSWER"
export const APP_REGISTER_EXAMINEE_REQUEST = "APP_REGISTER_EXAMINEE_REQUEST"
export const APP_REGISTER_EXAMINEE_SUCCESS = "APP_REGISTER_EXAMINEE_SUCCESS"
export const APP_REGISTER_EXAMINEE_FAILED = "APP_REGISTER_EXAMINEE_FAILED"
export const APP_GET_SCHOOL_REQUEST = "APP_GET_SCHOOL_REQUEST"
export const APP_GET_SCHOOL_SUCCESS = "APP_GET_SCHOOL_SUCCESS"
export const APP_GET_SCHOOL_FAILED = "APP_GET_SCHOOL_FAILED"
export const APP_GET_SCHOOL_DETAILS_REQUEST = "APP_GET_SCHOOL_DETAILS_REQUEST"
export const APP_GET_SCHOOL_DETAILS_SUCCESS = "APP_GET_SCHOOL_DETAILS_SUCCESS"
export const APP_GET_SCHOOL_DETAILS_FAILED = "APP_GET_SCHOOL_DETAILS_FAILED"
export const APP_GET_CATEGORY_REQUEST = "APP_GET_CATEGORY_REQUEST"
export const APP_GET_CATEGORY_SUCCESS = "APP_GET_CATEGORY_SUCCESS"
export const APP_GET_CATEGORY_FAILED = "APP_GET_CATEGORY_FAILED"
