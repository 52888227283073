import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HeaderPart from './Header'
import FooterPart from './Footer'

class NotFoundPage extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <HeaderPart/>
                <div className="card">
                    <div className="card-header text-center">
                        新卒検定エラー
                    </div>
                    <div className="card-body">
                        <div className="text-center py-6 text-xl">
                            <b>ページが見つかりません。</b>
                        </div>
                        <div className="col">
                            本検定に関する不明点等ありましたら、下記のメールアドレスにIDを記載の上、問い合わせください。<br/>
                            採用担当：{REACT_APP_OWNER}
                        </div>
                    </div>
                </div>
                <FooterPart/>
            </div>
        )
    }
}

NotFoundPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

NotFoundPage.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
    return {

    }
}

export default connect(mapStateToProps)(NotFoundPage)
