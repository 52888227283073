import React from 'react'

const FormGroup = ({ title, children, className = '', titleClassName = '' }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <h2 className={`simple__grp__title ${titleClassName}`}>{title}</h2>
      <div className="form__wrap">{children}</div>
    </div>
  )
}

export default FormGroup
