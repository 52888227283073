import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AnswerPart from "./AnswerPart";

class QuestionPart extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}

  updateAnswer(answer_id, value) {}

  render() {
    let { problem, question, answers } = this.props;
    let isDropdown = true;
    question.mst_exam_answers.forEach(function(answer, idx) {
      isDropdown &= answer.content.split(/%DROP%/).length > 1;
    })
    let cardClassName = "card-body container"
    if(isDropdown){
      cardClassName = cardClassName+" !pb-36"
    }
    return (
      <div className="card w-full !my-2">
        <div className="card-header !bg-black">
          設問 {problem.question_no}.{question.question_no}
        </div>
        <div className={cardClassName}>
          <div className="row">
            <div className="col">
              <div
                  style={{
                    overflow: "auto",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word"
                  }}
                  dangerouslySetInnerHTML={{ __html: question.content }}
                >
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="container" style={{ marginBottom: "5px" }}>
              {question.mst_exam_answers.map(function(answer, idx) {
                return (
                        <AnswerPart
                          key={idx}
                          choices={question.mst_exam_question_candidates}
                          answer={answer}
                          question={question}
                        />
                );
              })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuestionPart.propTypes = {
  dispatch: PropTypes.func.isRequired
};

QuestionPart.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    answers: {
      ...state.app.answers
    }
  };
}

export default connect(mapStateToProps)(QuestionPart);
