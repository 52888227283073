import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom'

import WelcomePage from './WelcomePage'
import ExamPage from './ExamPage'
import FinishPage from './FinishPage'
import LoginPage from './LoginPage'
import RegisterPage from './RegisterPage'
import AlreadyRegisteredPage from './AlreadyRegisteredPage'
import TempPage from './TempPage'
import ErrorPage from './ErrorPage'
import NotFoundPage from './NotFoundPage'

const App = () => {
    return (
            <Router>
                    <Switch>
                        <Route path="/login" component={LoginPage} />
                        <Route path="/register" component={RegisterPage} />
                        <Route path="/already-registered" component={AlreadyRegisteredPage} />
                        <Route path={REACT_APP_CONFIRM_URL} component={TempPage} />
                        <Route path="/welcome" component={WelcomePage} />
                        <Route path="/exam" component={ExamPage} />
                        <Route path="/finish" component={FinishPage} />
                        <Route path="/error" component={ErrorPage} />
                        <Route exact path="/" component={WelcomePage} />
                        <Route exact={true} path="*" component={NotFoundPage} />
                    </Switch>
            </Router>

    )
}

export default App;
