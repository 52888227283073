import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import HeaderPart from "./Header";
import FooterPart from "./Footer";
import { getExamOverview } from "./AppActions";

import { getToken, isStartedKentei } from "../utils";
import { Redirect } from "react-router";

class WelcomePage extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch(getExamOverview());
  }


  takeExam() {
    var allowBrowser = this.getSupportsEnv(this.getOS());
    if (
      allowBrowser.length == 0 ||
      allowBrowser[0].indexOf(this.getBrowser()) !== -1
    ) {
      if (
        window.confirm(
          "貴方の環境は受検推奨環境ではありませんので、受検しますか？"
        )
      ) {
        //this.context.router.history.push("/exam");
        this.props.history.push("/exam")
      }
    } else {
      //this.context.router.history.push("/exam");
      this.props.history.push("/exam")
    }
  }
  getBrowser() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("trident/7") !== -1) {
      return "IE11";
    } else if (userAgent.indexOf("edge") !== -1) {
      return "Edge";
    } else if (
      userAgent.indexOf("firefox") !== -1 &&
      userAgent.indexOf("chrome") === -1
    ) {
      /* FireFox. */
      return "Firefox";
    } else if (userAgent.indexOf("chrome") !== -1) {
      /* Google Chrome. */
      return "Chrome";
    } else if (userAgent.indexOf("safari") != -1) {
      /* Safari. */
      return "Safari";
    } else {
      return "Unsupported";
    }
  }
  getOS() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    var platform = window.navigator.platform.toLowerCase();
    //console.log(userAgent);
    //console.log(platform);
    if (userAgent.indexOf("windows phone") != -1) {
      /* Safari. */
      return "Windows Phone";
    } else if (
      userAgent.indexOf("android") != -1 &&
      platform.indexOf("mac") == -1 &&
      platform.indexOf("win") == -1
    ) {
      /* FireFox. */
      return "Android";
    } else if (userAgent.indexOf("iphone") != -1) {
      /* Safari. */
      return "iPhone";
    } else if (userAgent.indexOf("ipad") != -1) {
      /* Safari. */
      return "iPhone";
    } else {
      if (platform.indexOf("mac") != -1) {
        return "Mac";
      } else if (platform.indexOf("win") != -1) {
        /* Google Chrome. */
        return "Windows";
      } else {
        return "Unsupported";
      }
    }
  }
  getSupportsEnv(type) {
    var env = [
      ["Windows", "Chrome/ Edge/ Firefox"],
      ["Mac", "Chrome/ Safari/ Firefox"],
      ["Android", "Chrome"],
      ["iPhone", "Safari/ Chrome"]
    ];
    if (type !== undefined) {
      var nEnv = [];
      env.map(function(tenv, idx) {
        if (tenv[0] == type) {
          nEnv.push(tenv);
        }
      });
      env = nEnv;
    }
    return env;
  }
  render() {
    if (getToken() == "" || getToken() == null) {
      return <Redirect to="/login" />;
    }

    let environments = this.getSupportsEnv();
    let { category, errCode, testId } = this.props;
    if (errCode != null && errCode > 0) {
      if (errCode == 3) {
        return <Redirect to="/login" />;
      } else {
        return <Redirect to="/error" />;
      }
    }
    if (isStartedKentei(testId)) {
      return <Redirect to="/exam" />;
    }

    let shouldRender = category.category_name != undefined;
    let yourenv = this.getOS() + " / " + this.getBrowser();
    return (
      <div>
        <HeaderPart />
        {shouldRender ? (
            <div className="card">
              <div className="card-header text-lg text-center">新卒受検要綱</div>
              <div className="card-body container">
                <div className="row"
                  style={{
                    borderBottom: "1px lightgray solid"
                  }}
                >
                  <div className="col">受検コース</div>
                  <div className="col">{category.category_name}</div>
                  <div className="col">問題数</div>
                  <div className="col">
                    <big>
                      <b className="text-red-500">
                        {category.exam_total_number}
                      </b>
                    </big>
                    問
                  </div>
                  <div className="col">回答時間</div>
                  <div className="col">
                    <big>
                      <b className="text-red-500">{category.exam_time}</b>
                    </big>
                    分
                  </div>
                </div>
                <div className="row">
                  <div md="12">&nbsp;</div>
                </div>
                <div className="row">
                  <div className="col">動作保証環境</div>
                  <div className="col" style={{ textAlign: "right" }}>
                    <b>貴方の環境 ( {yourenv} ) </b>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <table className="table-auto w-full border-collapse border border-slate-200">
                      <thead>
                        <tr>
                          <th className="border border-slate-300 bg-slate-200">機種</th>
                          <th className="border border-slate-300 bg-slate-200">ブラウザ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {environments.map(function(env, idx) {
                          return (
                            <tr key={idx}>
                              <td className="px-2 border border-slate-100">{env[0]}</td>
                              <td className="px-2 border border-slate-100">{env[1]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row border border-slate-200 my-6 rounded-lg bg-yellow-50 p-4 !flex-col">
                  <div>
                    <big>
                      <b>■受検における注意事項</b>
                    </big>
                    <br />
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    <span
                      className="border-b border-b-slate-300"
                    >
                      <b>各設問へはページ下部の戻る、次へボタン</b>
                      で操作ください。
                    </span>
                    <br />
                    <small className="text-red-500">
                      <b>
                        決してブラウザの「戻る↔次へ」ボタンは使用しないでください。検定の不正終了となります。
                      </b>
                    </small>
                  </div>
                  <div className="col">&nbsp;</div>
                  <div className="col">
                    <span
                      className="border-b border-b-slate-300"
                    >
                      <b>
                        提出ボタンを押下し、受検完了画面まで表示させたのち試験を終了してください。検定の中断はできません。
                      </b>
                    </span>
                    <br />
                    <small>
                      回答が終わりましたら、右上の<b>提出ボタン</b>
                      から提出してください。
                      <b>受検完了の画面に遷移</b>
                      したうえで、ページを閉じて試験終了となります。
                    </small>
                  </div>
                  <div className="col">&nbsp;</div>
                  <div className="col">
                    <span
                      className="border-b border-b-slate-300"
                    >
                      <b>
                        実施前に受検場所・ネットワーク環境を確認ください。
                      </b>
                    </span>
                    <br />
                    <small>
                      検定の
                      <b className="text-red-500">一時停止</b>
                      はできません。論理的思考を確認する設問も多くあるため、集中して受検できる場所で受検ください。
                      また、不安定なネットワーク環境で受検の場合、検定が受けられない場合があります。
                      <b>
                        特に電車や車等での移動中はネットワーク環境が不安定となりますので受検は控えてください。
                      </b>
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col">&nbsp;</div>
                  <div className="col">
                    お申込み内容と異なる場合、受検推奨環境をお持ちでない場合等は、ご自身のIDと共に{REACT_APP_OWNER}にご連絡ください。<br />
                    受検準備が整いましたら、受検するを押下し受検を開始してください。一度受検を開始した場合は、いかなる理由があろうと再受検は認められませんので、ご注意ください。
                  </div>
                </div>
                <div className="row justify-center">
                    <button
                      type="button"
                      className="btn"
                      onClick={() => this.takeExam()}
                    >
                      受検する
                    </button>
                </div>
              </div>
            </div>
        ) : null}
        <FooterPart />
      </div>
    );
  }
}

WelcomePage.propTypes = {
  dispatch: PropTypes.func.isRequired
};

WelcomePage.contextTypes = {
  router: PropTypes.object
};

function mapStateToProps(state) {
  return {
    authenticated: state.app.authenticated,
    aolId: state.app.aolId,
    error: state.app.error,
    errCode: state.app.errCode,
    category: {
      ...state.app.category
    },
    testId: state.app.examination.id
  };
}

export default connect(mapStateToProps)(WelcomePage);
