import React from 'react'

const Loading = () => {
  return (
    <div className="fixed w-full h-full z-50 bg-white opacity-70 flex items-center justify-center">
      <div className="w-10 h-10 rounded-full animate-spin absolute border-2 border-solid border-blue-500 border-t-transparent"></div>
    </div>
  )
}

export default Loading
