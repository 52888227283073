export const mapToOptions = (arr) => {
  return arr.map((item) => {
    return { value: item, label: item }
  })
}
export const mapToIndexOptions = (arr) => {
  return arr.map((item, idx) => {
    return { value: idx, label: item }
  })
}

const currentYear = new Date().getFullYear()

const generateBirthYearList = () => {
  const startYear = 1995
  const endYear = currentYear - 15
  return generateYearList(startYear, endYear)
}

const generatePlannedGradYearList = () => {
  const startYear = currentYear - 2
  const endYear = currentYear + 4
  return generateYearList(startYear, endYear)
}

const generateYearList = (start, end) => {
  return Array(end - start + 1)
    .fill()
    .map((_, i) => start + i)
}

export const YEAR_OPTIONS = mapToOptions(generateBirthYearList())
export const MONTH_OPTIONS = mapToOptions([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])
export const DAY_OPTIONS = mapToOptions([
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
])
export const PLANNED_GRAD_YEAR_OPTIONS = mapToOptions(generatePlannedGradYearList())

export const SCHOOL_CATEGORY_OPTIONS = mapToIndexOptions(['大学院', '大学', '短期大学', '高等専門学校', '専門学校'])
export const INSTITUTION_CATEGORY_OPTIONS = mapToIndexOptions(['国立', '公立', '私立', '日本国外'])

export const SCHOOL_OUTSIDE_JP_OPTION = '3' // 日本国外
export const SCHOOL_NOT_LISTED_OPTION = '（リストにない学校）'
