import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HeaderPart from './Header'
import FooterPart from './Footer'
import {getToken,setToken,setKenteiData} from '../utils'

import { Redirect } from 'react-router'

class FinishPage extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
    }

    componentWillUnmount(){
        let {testId} = this.props
        setToken(null)
        setKenteiData(testId,null)
    }
    render() {
        if (getToken() == "" || getToken() == null) {
            return <Redirect to="/login" />;
        }
        return (
            <div>
                <HeaderPart/>
                <div className="card">
                    <div className="card-header text-center">
                        新卒検定
                    </div>
                    <div className="card-body px-4">
                        <div className="py-4">
                            新卒検定のご受検ありがとうございました。<br/>
                            選考結果につきましては、後日、採用担当よりご連絡いたします。
                        </div>
                        <div className="py-2">＜検定に関するお問い合わせはこちら＞</div>
                        <div className="py-2">
                        本検定に関する不明点等ありましたら、下記のメールアドレスにIDを記載の上、問い合わせください。<br />
                        採用担当：{REACT_APP_OWNER}<br/>
                        </div>
                        <div className="py-2"><b>Special Thanks</b> : 株式会社SHIFT　能力開発部</div>
                    </div>
                </div>
                <FooterPart/>
            </div>
        )
    }
}

FinishPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

FinishPage.contextTypes = {
    router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        authenticated: state.app.authenticated,
        aolId: state.app.aolId,
        testId: state.app.examination.id
    }
}

export default connect(mapStateToProps)(FinishPage)
