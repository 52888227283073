import React from 'react'
import FormItemContent from './FormItemContent'
import FormItemLabel from './FormItemLabel'

const FormItem = ({
  label,
  children,
  required,
  className,
  labelClassName,
  contentClassName,
  contentWrapperClassName,
  helperText,
  topBorder = true,
  labelStyle = {},
}) => {
  const cssTopBorder = topBorder ? 'border-t-[1px] border-dashed border-[#ddd]' : ''
  return (
    <div
      className={`form__item w-full flex flex-col md:flex-row items-center justify-start gap-8 my-2 ${cssTopBorder} ${className}`}
    >
      <FormItemLabel label={label} required={required} className={labelClassName} labelStyle={labelStyle} />
      <FormItemContent wrapperClassName={contentWrapperClassName} className={contentClassName} helperText={helperText}>
        {children}
      </FormItemContent>
    </div>
  )
}

export default FormItem
