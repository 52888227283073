import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

class FooterPart extends React.Component {
    constructor() {
        super()
        this.state = {

        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <footer className="fixed bottom-0 w-full left-0 bg-black items-center justify-between">
                <span className="block text-sm text-white text-center ">{REACT_APP_VERSION} &nbsp; &copy; SHIFT Inc.</span>
            </footer>
        )
    }
}

FooterPart.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

FooterPart.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps)(FooterPart)
