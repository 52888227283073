import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'

import { CATEGORY_ERROR_PATH } from '../constants'
import FooterPart from './Footer'
import HeaderPart from './Header'

class ErrorPage extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {}

  render() {
    let { errCode, error, errStatusCode } = this.props
    if (errCode <= 0) {
      return <Redirect to="/login" />
    }

    const renderTitle = () => {
      if (error.startsWith(CATEGORY_ERROR_PATH)) {
        return 'お探しのページが見つかりません。'
      }
      return error
    }

    const render500 = () => {
      return (
        <span>
          ご不便をおかけし申しわけございません。
          <br />
          しばらく時間をおいても事象が改善されない場合は、お手数ですがお問い合わせください。
        </span>
      )
    }

    const render400 = () => {
      if (error.startsWith(CATEGORY_ERROR_PATH)) {
        return (
          <span>
            申し訳ございません。お探しのページが見つかりませんでした。
            <br />
            URLが変更になった、もしくはページが削除された可能性がございます。
            <br />
            ブックマークされている場合はお手数ですが、登録されたURLの変更をお願いいたします。
            <br />
            不明点等あれば、以下のお問い合わせください。
          </span>
        )
      }
      return <span>本検定に関する不明点等ありましたら、下記のメールアドレスにIDを記載の上、問い合わせください。</span>
    }

    return (
      <div>
        <HeaderPart />
        <div className="card">
          <div className="card-header text-center">新卒検定エラー</div>
          <div className="card-body">
            <div className="py-2 text-lg font-semibold text-center">{renderTitle()}</div>
            <div className="py-10 px-4">
              {errStatusCode === 500 ? render500() : render400()}
              <br />
              採用担当：{REACT_APP_OWNER}
            </div>
          </div>
        </div>
        <FooterPart />
      </div>
    )
  }
}

ErrorPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

ErrorPage.contextTypes = {
  router: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    authenticated: state.app.authenticated,
    aolId: state.app.aolId,
    errCode: state.app.errCode,
    error: state.app.error,
    errStatusCode: state.app.errStatusCode,
  }
}

export default connect(mapStateToProps)(ErrorPage)
